// import P1 from '@/images/gallery/1.jpg';
import P2 from '@/images/gallery/2.jpg';
import P3 from '@/images/gallery/3.jpg';
import P4 from '@/images/gallery/4.jpg';
import P5 from '@/images/gallery/5.jpg';
import P6 from '@/images/gallery/6.jpg';
import P7 from '@/images/gallery/7.jpg';
import P8 from '@/images/gallery/8.jpg';
import P9 from '@/images/gallery/9.jpg';
import P10 from '@/images/gallery/10.jpg';
import P11 from '@/images/gallery/11.jpg';
import P12 from '@/images/gallery/12.jpg';
import P13 from '@/images/gallery/13.jpg';
import P14 from '@/images/gallery/14.jpg';
import P15 from '@/images/gallery/15.jpg';
import P16 from '@/images/gallery/16.jpg';
import P17 from '@/images/gallery/17.jpg';
import P18 from '@/images/gallery/18.jpg';
import P19 from '@/images/gallery/19.jpg';
import P20 from '@/images/gallery/20.jpg';
import P21 from '@/images/gallery/21.jpg';
import P22 from '@/images/gallery/22.jpg';
import P23 from '@/images/gallery/23.jpg';
import P24 from '@/images/gallery/24.jpg';
import P25 from '@/images/gallery/25.jpg';
import P26 from '@/images/gallery/26.jpg';
import P27 from '@/images/gallery/27.jpg';
import P28 from '@/images/gallery/28.jpg';
import P29 from '@/images/gallery/29.jpg';
import P30 from '@/images/gallery/30.jpg';
import P31 from '@/images/gallery/31.jpg';
import P32 from '@/images/gallery/32.jpg';
import P33 from '@/images/gallery/33.jpg';
import P34 from '@/images/gallery/34.jpg';
import P35 from '@/images/gallery/35.jpg';
import P36 from '@/images/gallery/36.jpg';
import P37 from '@/images/gallery/37.jpg';
import P38 from '@/images/gallery/38.jpg';
import P39 from '@/images/gallery/39.jpg';
import P40 from '@/images/gallery/40.jpg';
import P41 from '@/images/gallery/41.jpg';
import P42 from '@/images/gallery/42.jpg';
import P43 from '@/images/gallery/43.jpg';
import P44 from '@/images/gallery/44.jpg';
import P45 from '@/images/gallery/45.jpg';
import P46 from '@/images/gallery/46.jpg';
import P47 from '@/images/gallery/47.jpg';
import P48 from '@/images/gallery/48.jpg';
import P49 from '@/images/gallery/49.jpg';
import P50 from '@/images/gallery/50.jpg';
import P51 from '@/images/gallery/51.jpg';
import P52 from '@/images/gallery/52.jpg';
import P53 from '@/images/gallery/53.jpg';
import P54 from '@/images/gallery/54.jpg';
import P55 from '@/images/gallery/55.jpg';
import P56 from '@/images/gallery/56.jpg';
import P57 from '@/images/gallery/57.jpg';
import P58 from '@/images/gallery/58.jpg';
import P59 from '@/images/gallery/59.jpg';
import P60 from '@/images/gallery/60.jpg';
import P61 from '@/images/gallery/61.jpg';
import P62 from '@/images/gallery/62.jpg';
import P63 from '@/images/gallery/63.jpg';
import P64 from '@/images/gallery/64.jpg';
import P65 from '@/images/gallery/65.jpg';
import P66 from '@/images/gallery/66.jpg';
import P67 from '@/images/gallery/67.jpg';
import P68 from '@/images/gallery/68.jpg';
import P69 from '@/images/gallery/69.jpg';
import P70 from '@/images/gallery/70.jpg';
import P71 from '@/images/gallery/71.jpg';
import P72 from '@/images/gallery/72.jpg';
import P73 from '@/images/gallery/73.jpg';
import P74 from '@/images/gallery/74.jpg';
import P75 from '@/images/gallery/75.jpg';
import P76 from '@/images/gallery/76.jpg';
import P77 from '@/images/gallery/77.jpg';
import P78 from '@/images/gallery/78.jpg';
import P79 from '@/images/gallery/79.jpg';
import P80 from '@/images/gallery/80.jpg';
import P81 from '@/images/gallery/81.jpg';
import P82 from '@/images/gallery/82.jpg';
import P83 from '@/images/gallery/83.jpg';
import P84 from '@/images/gallery/84.jpg';
import P85 from '@/images/gallery/85.jpg';
import P86 from '@/images/gallery/86.jpg';
import P87 from '@/images/gallery/87.jpg';
// import P88 from '@/images/gallery/88.jpg';
// import P89 from '@/images/gallery/89.jpg';
// import P90 from '@/images/gallery/90.jpg';
// import P91 from '@/images/gallery/91.jpg';

export const photos = [
  // {
  //   // for first photo, please upload to blogger.com
  //   // this will help us to improve page performance
  //   original: `https://lh3.googleusercontent.com/-6kf0CzGMDss/YZ-SdXsBpeI/AAAAAAAAACA/43O2QDPXMgIBXByZshakS0JiX1G3DlcUQCLcBGAsYHQ/s16000/1.jpg`,
  // },
  // {
  //   original: P1,
  //   // thumbnail: P1,
  // },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  {
    original: P5,
    // thumbnail: P5,
  },
  {
    original: P6,
    // thumbnail: P6,
  },
  {
    original: P7,
    // thumbnail: P7,
  },
  {
    original: P8,
    // thumbnail: P8,
  },
  {
    original: P9,
    // thumbnail: P9,
  },
  {
    original: P10,
    // thumbnail: P10,
  },
  {
    original: P11,
    // thumbnail: P11,
  },
  {
    original: P12,
    // thumbnail: P12,
  },
  {
    original: P13,
    // thumbnail: P13,
  },
  {
    original: P14,
    // thumbnail: P14,
  },
  {
    original: P15,
    // thumbnail: P15,
  },
  {
    original: P16,
    // thumbnail: P16,
  },
  {
    original: P17,
    // thumbnail: P17,
  },
  {
    original: P18,
    // thumbnail: P18,
  },
  {
    original: P19,
    // thumbnail: P19,
  },
  {
    original: P20,
    // thumbnail: P20,
  },
  {
    original: P21,
    // thumbnail: P21,
  },
  {
    original: P22,
    // thumbnail: P22,
  },
  {
    original: P23,
    // thumbnail: P23,
  },
  {
    original: P24,
    // thumbnail: P24,
  },
  {
    original: P25,
    // thumbnail: P25,
  },
  {
    original: P26,
    // thumbnail: P26,
  },
  {
    original: P27,
    // thumbnail: P27,
  },
  {
    original: P28,
    // thumbnail: P28,
  },
  {
    original: P29,
    // thumbnail: P29,
  },
  {
    original: P30,
    // thumbnail: P30,
  },
  {
    original: P31,
    // thumbnail: P31,
  },
  {
    original: P32,
    // thumbnail: P32,
  },
  {
    original: P33,
    // thumbnail: P3,
  },
  {
    original: P34,
    // thumbnail: P4,
  },
  {
    original: P35,
    // thumbnail: P5,
  },
  {
    original: P36,
    // thumbnail: P6,
  },
  {
    original: P37,
    // thumbnail: P7,
  },
  {
    original: P38,
    // thumbnail: P8,
  },
  {
    original: P39,
    // thumbnail: P9,
  },
  {
    original: P40,
    // thumbnail: P10,
  },
  {
    original: P41,
    // thumbnail: P1,
  },
  {
    original: P42,
    // thumbnail: P2,
  },
  {
    original: P43,
    // thumbnail: P3,
  },
  {
    original: P44,
    // thumbnail: P4,
  },
  {
    original: P45,
    // thumbnail: P5,
  },
  {
    original: P46,
    // thumbnail: P6,
  },
  {
    original: P47,
    // thumbnail: P7,
  },
  {
    original: P48,
    // thumbnail: P8,
  },
  {
    original: P49,
    // thumbnail: P9,
  },
  {
    original: P50,
    // thumbnail: P10,
  },
  {
    original: P51,
    // thumbnail: P1,
  },
  {
    original: P52,
    // thumbnail: P2,
  },
  {
    original: P53,
    // thumbnail: P3,
  },
  {
    original: P54,
    // thumbnail: P4,
  },
  {
    original: P55,
    // thumbnail: P5,
  },
  {
    original: P56,
    // thumbnail: P6,
  },
  {
    original: P57,
    // thumbnail: P7,
  },
  {
    original: P58,
    // thumbnail: P8,
  },
  {
    original: P59,
    // thumbnail: P9,
  },
  {
    original: P60,
    // thumbnail: P10,
  },
  {
    original: P61,
    // thumbnail: P1,
  },
  {
    original: P62,
    // thumbnail: P2,
  },
  {
    original: P63,
    // thumbnail: P3,
  },
  {
    original: P64,
    // thumbnail: P4,
  },
  {
    original: P65,
    // thumbnail: P5,
  },
  {
    original: P66,
    // thumbnail: P6,
  },
  {
    original: P67,
    // thumbnail: P7,
  },
  {
    original: P68,
    // thumbnail: P8,
  },
  {
    original: P69,
    // thumbnail: P9,
  },
  {
    original: P70,
    // thumbnail: P10,
  },
  {
    original: P71,
    // thumbnail: P1,
  },
  {
    original: P72,
    // thumbnail: P2,
  },
  {
    original: P73,
    // thumbnail: P3,
  },
  {
    original: P74,
    // thumbnail: P4,
  },
  {
    original: P75,
    // thumbnail: P5,
  },
  {
    original: P76,
    // thumbnail: P6,
  },
  {
    original: P77,
    // thumbnail: P7,
  },
  {
    original: P78,
    // thumbnail: P8,
  },
  {
    original: P79,
    // thumbnail: P9,
  },
  {
    original: P80,
    // thumbnail: P10,
  },
  {
    original: P81,
    // thumbnail: P1,
  },
  {
    original: P82,
    // thumbnail: P2,
  },
  {
    original: P83,
    // thumbnail: P3,
  },
  {
    original: P84,
    // thumbnail: P4,
  },
  {
    original: P85,
    // thumbnail: P5,
  },
  {
    original: P86,
    // thumbnail: P6,
  },
  {
    original: P87,
    // thumbnail: P7,
  },
  // {
  //   original: P88,
  //   // thumbnail: P8,
  // },
  // {
  //   original: P89,
  //   // thumbnail: P9,
  // },
  // {
  //   original: P90,
  //   // thumbnail: P10,
  // },
  // {
  //   original: P91,
  //   // thumbnail: P10,
  // },
];
